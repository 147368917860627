import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/mghealth-solutions/src/components/Layout/Layout.js";
import SEO from "layout/SEO";
import HomeBanner from "components/StaticPages/Home/HomeBanner";
import Container from "layout/Container";
import ContactUs from "components/StaticPages/HelpCenter/ContactUs";
import OrderForm from "components/StaticPages/Home/OrderForm";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" mdxType="SEO" />
    <HomeBanner mdxType="HomeBanner" />
    <Container isCentered mdxType="Container">
  <OrderForm mdxType="OrderForm" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React, { useState, useContext } from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import { Formik, Form } from "formik"
import classNames from "classnames"

import FormInput from "elements/Form/FormInput"
import FormTextArea from "../../Elements/Form/FormTextarea"
// import FormSelect from "../../Elements/Form/FormSelect"
import FormFileupload from "../../Elements/Form/FormFileupload"
// import subjects from "../utils/emailSubjects.json"
import {
  orderFormValidationSchema,
  orderFormInitialValues,
} from "../utils/formData"
import { initialState } from "../../../context/AppContext"
import { sendInquiry } from "../services/inquiry"
import { AppContext } from "../../../context/AppContext"
import DocumentsCardRow from "../../Elements/Dropzone/DocumentsCardRow"

const OrderForm = () => {
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState({
    message: "",
    active: false,
  })

  const { state, dispatch } = useContext(AppContext)
  const { orderForm, documents } = state
  const { prescriptionFiles } = orderForm

  const closeNotification = () => {
    setNotification({ message: "", active: false, type: "" })
  }

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    sendInquiry(
      prescriptionFiles,
      values,
      () => {
        resetForm()
        setLoading(false)
        dispatch({
          type: "SAVE_ORDER_FORM",
          payload: {},
        })
        dispatch({
          type: "SAVE_PRESCRIPTION_FILES",
          payload: [],
        })
        setNotification({
          active: true,
          type: "success",
          message:
            "Thank you for your inquiry! We'll get back to you within 24 hours.",
        })
      },
      () => {
        setLoading(false)
        setNotification({
          active: true,
          type: "danger",
          message:
            "Something went wrong with sending your email. Please try again.",
        })
      }
    )
  }

  const handleDeleteDocument = (index) => {
    let tempPrescriptionFiles = [...prescriptionFiles]
    tempPrescriptionFiles.splice(index, 1)
    dispatch({
      type: "SAVE_PRESCRIPTION_FILES",
      payload: tempPrescriptionFiles,
    })
  }

  return (
    <div>
      <section id="order-form">
        <h2>Order Form</h2>
        <hr />

        <Formik
          initialValues={{ ...initialState.orderForm }}
          onSubmit={handleSubmit}
          validationSchema={orderFormValidationSchema}
        >
          {({ values }) => (
            <Form autoComplete="off">
              <FormInput
                name="fullName"
                placeholder="Juana Dela Cruz"
                value={values.fullName}
                label="Full Name"
                isRequired
              />
              <FormInput
                name="email"
                placeholder="jdelacruz@mail.com"
                inputmode="email"
                value={values.email}
                label="Email"
                isRequired
              />

              <FormTextArea
                name="emailBody"
                value={values.emailBody}
                label="Message"
                isRequired
              />
              {prescriptionFiles?.length > 0 ? (
                prescriptionFiles.map((prescriptionFile) => (
                  <div className="my-2">
                    <p className="is-size-6 mb-1">Upload Prescription</p>
                    <DocumentsCardRow
                      fileName={prescriptionFile.name}
                      oldFileName={prescriptionFile.oldname}
                      handleDeleteDocument={handleDeleteDocument}
                      file={prescriptionFile}
                    />
                  </div>
                ))
              ) : (
                <FormFileupload
                  name="prescriptionFile"
                  label="Upload Prescription"
                  isRequired
                />
              )}
              {notification.active && (
                <div className={`notification is-${notification.type}`}>
                  <button
                    className="delete"
                    type="button"
                    onClick={closeNotification}
                  />
                  {notification.message}
                </div>
              )}
              <button
                type="submit"
                className={classNames(
                  "button is-fullwidth is-primary has-text-centered",
                  {
                    "is-loading": loading,
                  }
                )}
              >
                Send
              </button>
            </Form>
          )}
        </Formik>
      </section>
    </div>
  )
}

export default OrderForm
